import IResources from "../../static/assets/accel/i_resources.png"
import IFunding from "../../static/assets/accel/i_funding.png"
import ITraining from "../../static/assets/accel/i_training.png"
import INetwork from "../../static/assets/accel/i_network.png"

export const whyKeyla = [
  {
    id: 12112,
    icon: IResources,
    title: "Resources",
    info:
      "From cutting-edge technology to state-of-the-art facilities, we offer the resources necessary to develop and refine your product.",
  },
  {
    id: 34241,
    icon: IFunding,
    title: "Funding",
    info:
      " Secure the financial backing you need to turn your idea into a reality. We provide access to funding opportunities that will fuel your entrepreneurial  journey.",
  },
  {
    id: 13251,
    icon: ITraining,
    title: "Training",
    info:
      "Hone your skills and stay ahead of the curve with specialized training programs. Our experts will guide you through every step of the entrepreneurial process..",
  },
  {
    id: 12535,
    icon: INetwork,
    title: "Networks",
    info:
      "Connect with industry experts, mentors, and like-minded entrepreneurs. Build invaluable relationships that will contribute to your success..",
  },
]

export const navLinks = [
  {
    name: "About",
  },
  {
    name: "Services",
  },
  {
    name: "Accelerator",
  },
  {
    name: "Contact",
  },
]
