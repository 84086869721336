import React, { useState } from "react"
import BrandLogo from "../../../static/assets/brand/logo.svg"
import axios from "axios"
import Swal from "sweetalert2"

const Modal = ({ isVisible, onClose }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [altPhoneNo, setAltPhoneNo] = useState("")
  const [position, setPosition] = useState("")
  const [address, setAddress] = useState("")
  const [nin, setNin] = useState("")
  const [website, setWebsite] = useState("")
  const [startUpDesc, setStartUpDesc] = useState("")
  const [profitChannel, setProfitChannel] = useState("")
  const [competitors, setCompetitors] = useState("")
  const [targetAudience, setTargetAudience] = useState("")
  const [competitiveAdv, setCompetitiveAdv] = useState("")
  const [strategy, setStrategy] = useState("")
  const [startupIdeaName, setStartupIdeaName] = useState("")
  const [ideaCategory, setIdeaCategory] = useState("")
  const [howYouHeard, setHowYouHeard] = useState("")
  const [selectedGender, setSelectedGender] = useState(null)
  const [selectedPitchDeck, setSelectedPitchDeck] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [active, setActive] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")

  // useEffect(() => {
  //   console.log("Selected Image:", selectedImage)
  // }, [selectedImage])

  if (!isVisible) return null

  const handleCheckboxChange = event => {
    setSelectedGender(event.target.value)
  }

  const handleCLose = e => {
    if (e.target.id === "wrapper") onClose()
  }

  const handleIdeaCategory = e => {
    const selectedValue = e.target.value
    setIdeaCategory(selectedValue)
  }

  const handleHowYouHeard = e => {
    const selectedValue = e.target.value
    setHowYouHeard(selectedValue)
  }

  const handleFileChange = e => {
    const file = e.target.files[0]
    setSelectedPitchDeck(file)

    // console.log(selectedImage)
  }

  const convertToSnakeCase = str => {
    return str.replace(/\s+/g, "_").toLowerCase()
  }

  // Handling Submit
  const handleSubmit = async e => {
    e.preventDefault()

    try {
      setIsLoading(true)
      if (!selectedPitchDeck) {
        alert("Please select a valid file type.")
        return
      }

      const baseURL = "https://accelerator-api.keyla.ng"

      // Changing it to a snake_case format
      const fileExtension = selectedPitchDeck.name.split(".").pop()
      const snakeCaseIdeaName = convertToSnakeCase(startupIdeaName)
      const newFileName = `${snakeCaseIdeaName}.${fileExtension}`

      const renamedFile = new File([selectedPitchDeck], newFileName, {
        type: selectedPitchDeck.type,
      })

      const formData = new FormData()
      formData.append("file", renamedFile)

      // Making the Request for the file Upload
      const responseUpload = await axios.post(`${baseURL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      if (responseUpload.status === 200) {
        // Extract the previewURL from the data response
        const pitchDeckURL = responseUpload.data.preview_url

        // Make the second request for the Form inputs
        const responseForm = await axios.post(
          `${baseURL}/append`,
          {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNo,
            altPhoneNumber: altPhoneNo,
            position: position,
            personalAddress: address,
            gender: selectedGender,
            nin: nin,
            startupIdeaName: startupIdeaName,
            startupIdeaCategory: ideaCategory,
            websiteURL: website,
            startupIdeaDescription: startUpDesc,
            profitChannel: profitChannel,
            competitors: competitors,
            targetAudience: targetAudience,
            competitiveAdvantage: competitiveAdv,
            acquisitionStrategy: strategy,
            howYouHeard: howYouHeard,
            pitchDeck: pitchDeckURL,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )

        if (responseForm.status === 200) {
          // If the form submission is successful, clear the form inputs
          setFirstName("")
          setLastName("")
          setEmail("")
          setPhoneNo("")
          setAltPhoneNo("")
          setPosition("")
          setAddress("")
          setNin("")
          setWebsite("")
          setStartUpDesc("")
          setProfitChannel("")
          setCompetitors("")
          setTargetAudience("")
          setCompetitiveAdv("")
          setStrategy("")
          setStartupIdeaName("")
          setIdeaCategory("")
          setHowYouHeard("")
          setSelectedGender(null)
          setSelectedPitchDeck(null)

          // Set active to false for 2 seconds to display Submit Successful message
          setActive(false)

          // After 2 seconds, set active back to true to Hide Submit Successful message
          setTimeout(() => {
            setActive(true)
          }, 2000)
        } else {
          // Display an error message for form submission failure
          await Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${responseForm?.error?.message}`,
            showConfirmButton: false,
            timer: 1500,
          })
        }
      } else {
        // Display an error message for file upload failure
        await Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${responseUpload?.error?.message}`,
          showConfirmButton: false,
          timer: 1500,
        })
      }
    } catch (error) {
      // If any error occurs during the process, you can handle it here
      console.error("Error:", error)
      // Display an appropriate error message to the user if needed
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      id="wrapper"
      onClick={handleCLose}
      className="fixed flex justify-center items-center inset-0 bg-black bg-opacity-25 backdrop-blur-sm "
    >
      <div className=" flex flex-col gap-4 relative">
        {active === false ? (
          <div
            className="bg-white
           md:w-[400px] w-fit m-4 md:m-0 h-[400px]  overflow-y-auto rounded-[12px]"
          >
            <div className="p-4 py-8 text-gray-900">
              <h1 className="text-4xl md:text-5xl font-black text-center uppercase pt-4">
                We got your application.
              </h1>

              <p className="pt-5 text-xl text-center ">
                We just got your application. We'll analyze your information and
                get back to you.
              </p>

              <div className="flex justify-center my-8">
                <div className="bg-gray-700 h-2.5 w-[50%]"></div>
              </div>

              <h3 className=" text-center text-xl">Best Wishes</h3>
            </div>
          </div>
        ) : (
          <div className="bg-white md:w-[600px] w-fit m-4 md:m-0 h-[520px] overflow-y-auto rounded-[12px]">
            <div className="top-0 sticky w-full bg-white py-3 px-4">
              <img src={BrandLogo} alt="logo" className="w-28" />
              <h2 className="text-lg">
                Help us get to know you better! Fill out the form below.
              </h2>
            </div>
            <div className="p-5 md:p-4">
              <form onSubmit={handleSubmit} className="pt flex flex-col gap-3">
                {/* First Name/*/}
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    First name <span className="text-red-600">*</span>
                  </label>

                  <input
                    type="text"
                    id="first_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="John"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    required
                  />
                </div>

                {/* Last Name */}
                <div>
                  <label
                    htmlFor="last-name"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Last name <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Doe"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    required
                  />
                </div>

                {/* Email */}
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Email <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="john.doe@keyla.ng"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>

                {/* Phone Number */}
                <div>
                  <label
                    htmlFor="number"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Phone <span className="text-red-600">*</span>
                  </label>

                  <input
                    type="text"
                    id="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 outline-none"
                    placeholder="0800-000-0000"
                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    value={phoneNo}
                    onChange={e => setPhoneNo(e.target.value)}
                    required
                  />
                </div>

                {/* Alt Phone Number */}
                <div>
                  <label
                    htmlFor="alt-number"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Alternative Phone Number
                    {/*<span className="text-red-600">*</span>*/}
                  </label>

                  <input
                    type="text"
                    id="alt-number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 outline-none"
                    placeholder="0800-000-0000"
                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    value={altPhoneNo}
                    onChange={e => setAltPhoneNo(e.target.value)}
                  />
                </div>

                {/* Position */}
                <div>
                  <label
                    htmlFor="position"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Position (Must be Executive){" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="position"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Position"
                    value={position}
                    onChange={e => setPosition(e.target.value)}
                    required
                  />
                </div>

                {/* Address */}
                <div>
                  <label
                    htmlFor="address"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Address <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Address"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    required
                  />
                </div>

                {/* Gender */}
                <div>
                  <label
                    htmlFor="gender"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Gender
                  </label>

                  <div className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      value="Male"
                      id="male-checkbox"
                      checked={selectedGender === "Male"}
                      onChange={handleCheckboxChange}
                      className="w-2 h-2 rounded"
                    />

                    <label className="ms-2 text-sm " id="male-checkbox">
                      Male
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      value="Female"
                      id="female-checkbox"
                      checked={selectedGender === "Female"}
                      onChange={handleCheckboxChange}
                      className="w-2 h-2 rounded"
                    />

                    <label id="female-checkbox" className="ms-2 text-sm">
                      Female
                    </label>
                  </div>
                </div>

                {/* NIN */}
                <div className="pt-3">
                  <label
                    htmlFor="nin"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    NIN <span className="text-red-600">*</span>
                  </label>

                  <input
                    type="text"
                    id="nin"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="National Identity Number"
                    value={nin}
                    onChange={e => setNin(e.target.value)}
                    required
                  />
                </div>

                {/* Startup Idea Name */}
                <div className="pt-3">
                  <label
                    htmlFor="startup-name"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Startup/Idea name <span className="text-red-600">*</span>
                  </label>

                  <input
                    type="text"
                    id="startup-name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Startup/Idea name"
                    value={startupIdeaName}
                    onChange={e => setStartupIdeaName(e.target.value)}
                    required
                  />
                </div>

                {/* Startup/idea Category */}
                <div>
                  <label
                    htmlFor="default"
                    className="block mb-2 text-sm font-semibold text-gray-900"
                  >
                    Startup/Idea category{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <select
                    id="default"
                    className="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none"
                    value={ideaCategory}
                    onChange={handleIdeaCategory}
                  >
                    <option value="">Choose a category</option>
                    <option value="Fintech">Fintech</option>
                    <option value="Agricultural Technology">
                      Agricultural Technology
                    </option>
                    <option value="Education Technology">
                      Education Technology
                    </option>
                    <option value="Renewable Energy">Renewable Energy</option>
                    <option value="Technology Services">
                      Technology Services
                    </option>
                    <option value="IOT">IOT</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Transportation">Transportation</option>
                    <option value="Electronics">Electronics</option>
                    <option value="Health Technology">Health Technology</option>
                    <option value="Internet Services">Internet Services</option>
                    <option value="Construction">Construction</option>
                    <option value="Artificial Intelligence">
                      Artificial Intelligence
                    </option>
                    <option value="Communication">Communication</option>
                  </select>
                </div>

                {/* Website URL */}
                <div className="">
                  <label
                    htmlFor="website"
                    className="block mb-2 text-sm font-semibold text-gray-900"
                  >
                    Website URL(if available)
                  </label>
                  <input
                    type="url"
                    id="website"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ring-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none"
                    placeholder="https://www.keyla.ng"
                    value={website}
                    onInvalid={e => {
                      e.preventDefault()
                      setErrorMessage("Invalid URL")
                    }}
                    onChange={e => {
                      setWebsite(e.target.value)

                      if (!e.target.value.match(/^(https?|ftp):\/\//)) {
                        setErrorMessage(
                          'Please include "http://" or "https://" in the URL'
                        )
                      } else {
                        setErrorMessage("")
                      }
                    }}
                  />
                  {errorMessage && (
                    <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
                  )}
                </div>

                {/* Startup/Idea Description */}
                <div>
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-semibold text-gray-900"
                  >
                    Startup/Idea description (Explain your idea or startup){" "}
                    <span className="text-red-600">*</span>
                  </label>

                  <textarea
                    id="message"
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500  placeholder-gray-400 ring-blue-500 outline-none"
                    placeholder="Write your thoughts here..."
                    value={startUpDesc}
                    onChange={e => setStartUpDesc(e.target.value)}
                  ></textarea>
                </div>

                {/* Upload  */}
                <div>
                  <label
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                    htmlFor="file_input"
                  >
                    Upload Pitch Deck (pdf, doc, ppt){" "}
                    <span className="text-red-600">*</span>
                  </label>

                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 outline-none placeholder-gray-400 p-1"
                    id="file_input"
                    type="file"
                    accept=".pdf, .doc, .docx, .ppt, .pptx"
                    // value={selectedImage}
                    onChange={handleFileChange}
                  />
                </div>

                {/* How make money */}
                <div>
                  <label
                    htmlFor="how"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    How do you(will) make money?{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="how"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Your answer "
                    value={profitChannel}
                    onChange={e => setProfitChannel(e.target.value)}
                    required
                  />
                </div>

                {/* competitors */}
                <div>
                  <label
                    htmlFor="competitor"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Who are your competitors?{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="competitor"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Your answer "
                    value={competitors}
                    onChange={e => setCompetitors(e.target.value)}
                    required
                  />
                </div>

                {/* Audience */}
                <div>
                  <label
                    htmlFor="audience"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    Who are your target audience?{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="audience"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Your answer "
                    value={targetAudience}
                    onChange={e => setTargetAudience(e.target.value)}
                    required
                  />
                </div>

                {/* competitive Advantage */}
                <div>
                  <label
                    htmlFor="advantage"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    What is your competitive advantages?{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="advantage"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Your answer "
                    value={competitiveAdv}
                    onChange={e => setCompetitiveAdv(e.target.value)}
                    required
                  />
                </div>

                {/* customer Acquisition */}
                <div>
                  <label
                    htmlFor="strategy"
                    className="block mb-2 text-sm font-semibold text-gray-900 "
                  >
                    What is your customer acquisition strategy?{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="strategy"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none "
                    placeholder="Your answer "
                    value={strategy}
                    onChange={e => setStrategy(e.target.value)}
                    required
                  />
                </div>

                {/* How do you hear about us */}
                <div>
                  <label
                    htmlFor="hear"
                    className="block mb-2 text-sm font-semibold text-gray-900"
                  >
                    How do you hear about us{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <select
                    id="hear"
                    className="bg-gray-50 border border-gray-300 text-gray-900 mb-6 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-400 outline-none"
                    value={howYouHeard}
                    onChange={handleHowYouHeard}
                  >
                    <option value="">Choose</option>
                    <option value="Referral">Referral</option>
                    <option value="Social media Ads">Social media Ads</option>
                    <option value="Online publication">
                      Online publication
                    </option>
                    <option value="Search engine">Search engine</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <div className="flex items-center gap-2">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 outline-none"
                    required
                  />
                  <label
                    htmlFor="link-checkbox"
                    className=" text-sm font-semibold text-gray-900 "
                  >
                    I affirm that the information provided above are accurate
                  </label>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full mt-4 bg-primary py-2 rounded text-white"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal
