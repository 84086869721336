import React from "react"
import Layout from "../components/global/Layout"
import Image1 from "../../static/assets/accel/image1.png"
import Image2 from "../../static/assets/accel/image2.png"
import Image3 from "../../static/assets/accel/image3.png"
import Image4 from "../../static/assets/accel/image4.png"
import Image5 from "../../static/assets/accel/image5.png"
import Pattern1 from "../../static/assets/accel/pattern1.png"
import Pattern2 from "../../static/assets/accel/pattern2.png"
import Hub from "../../static/assets/accel/hub.png"
import Support from "../../static/assets/accel/support.png"
import Trophy from "../../static/assets/accel/trophy.png"
import { whyKeyla } from "../constants"
import Modal from "../components/global/Modal"

function AcceleratorProgram() {
  const [showModal, setShowModal] = React.useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      <Layout lightNav={true}>
        <div className="w-full">
          <div className="flex flex-col md:flex-row w-full">
            <div className="w-full md:w-6/12 bg-primary relative md:h-[612px] h-fit">
              <img
                className="absolute -top-16 blend-pattern w-full z-20"
                src={Pattern1}
                alt="Pattern1"
              />
              {/* <img
                className="absolute -bottom-16 -z-20 blend- w-full"
                src={Pattern2}
                alt="Pattern2"
              /> */}
              <div className="py-8 xl:py-16 px-6 md:py-16 lg:py-20 xl:px-20 space-y-6 md:space-y-7">
                <h2 className="text-4xl md:text-[33px] lg:text-[45px] xl:text-[50px] font-title font-bold text-white leading-[1.1]">
                  Keyla Tech- <br className="md:flex hidden" /> Accelerator
                  Program
                </h2>
                <p className="text-white font-light text-base xl:text-lg tracking-wide">
                  Keyla Technologies is pleased to announce first-ever “Keyla
                  Tech-Accelerator Program 1.0”. Keyla Tech-Accelerator program
                  is a program that gives tech and business personnel
                  opportunity to bring their business to life.
                  <p className="pt-4 text-white font-light text-base xl:text-lg tracking-wide ">
                    Are you a visionary tech entrepreneur with a groundbreaking
                    idea but lacking the resources to turn it into reality?
                  </p>
                  <p className="pt-4 text-white font-light text-base xl:text-lg tracking-wide">
                    Look no further! Keyla Technologies is thrilled to introduce
                    the Keyla Tech-Accelerator Program, a game-changing
                    initiative designed to empower individuals and startups in
                    bringing their tech dreams to life.
                  </p>
                </p>

                <button
                  className="bg-primary hover:bg-sky-500 hover:border-none mb-4 text-white px-5 py-2 rounded-full font-light text-sm md:text-base border"
                  onClick={toggleModal}
                >
                  Apply Now
                </button>
              </div>
            </div>
            <div className="w-full md:w-6/12 md:h-[612px] h-[570px]">
              <img className="w-full object-cover h-full" src={Image1} alt="" />
            </div>
          </div>
        </div>

        {/* Decoding Keyla Tech */}
        <div className="h-12 md:h-20"></div>
        <div className="flex flex-col-reverse gap-10 md:gap-0 md:flex-row px-6 xl:px-20 justify-center items-center">
          <div className="w-full md:w-6/12 mb-6 md:mb-0 flex justify-start items-start">
            <img className="w-[87%] object-cover mx-auto" src={Image2} alt="" />
          </div>
          <div className="w-full md:w-6/12 md:p-16 space-y-4 ">
            <h3 className="font-bold text-xl md:text-[28px] ">
              Decoding Keyla Tech-Accelerator
            </h3>
            <p className="font-medium tracking-wide text-base md:text-sm lg:text-base xl:text-lg">
              At Keyla Technologies, our mission is to deliver top-notch
              technological services that drive business efficiency and customer
              satisfaction. With a commitment to quality, we are excited to
              extend our support to the tech community through the Keyla
              Tech-Accelerator Program.
              <br />
              <br />
              More than just a program, Keyla Tech-Accelerator is your passport
              to turning innovative tech ideas into thriving ventures. We
              recognize that brilliant minds often lack the resources needed to
              take flight, and that's where we step in!
            </p>
            <button
              className="bg-primary text-white px-4 py-2 rounded-full font-light text-sm md:text-base"
              onClick={toggleModal}
            >
              Apply Now
            </button>
          </div>
        </div>
        <div className="h-12 md:h-24"></div>
        {/* End Decoding Keyla Tech */}
        {/* Why choose Keyla Tech */}
        <div className="flex flex-col lg:flex-row px-6 xl:px-32 justify-center lg:items-start items-center">
          <div className="w-full lg:w-6/12 mb-6 md:mb-0 space-y-6 md:space-y-10">
            <h3 className="font-bold text-2xl md:text-4xl">
              Why Choose{" "}
              <span className="text-primary font-bold text-2xl lg:text-3xl md:text-4xl">
                Keyla Tech- <br className="lg:flex hidden" /> Accelerator?
              </span>
            </h3>

            <div className="flex md:justify-center lg:justify-start">
              <img className="w-full md:w-[75%]" src={Image3} alt="" />
            </div>
          </div>
          <div className="w-full md:w-6/12 ">
            <div className="flex md:flex-row flex-col w-full justify-center gap-4 md:gap-6 lg:gap-4 xl:gap-6 items-center pt-7 lg:pt-0">
              {whyKeyla.slice(0, 2).map(item => (
                <div key={item.id}>
                  <div className="bg-white shadow-2xl space-y-3 p-6 md:p-6 rounded-xl w-full lg:w-[239px] md:w-[300px] h-[280px]">
                    <img className="mx-auto" src={item.icon} alt="" />
                    <h3 className="text-2xl font-bold text-center">
                      {item.title}
                    </h3>
                    <p className="lg:text-sm text-base font-medium text-[#4B4B4B] text-center">
                      {item.info}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex md:flex-row flex-col w-full justify-center gap-4 md:gap-6 lg:gap-4 xl:gap-8 items-center pt-4 xl:pt-6 md:pt-6 lg:pt-4">
              {whyKeyla.slice(2, 4).map(item => (
                <div key={item.id}>
                  <div className="bg-white shadow-2xl space-y-3 p-6 md:p-6 rounded-xl w-full lg:w-[239px] md:w-[300px] h-[280px]">
                    <img className="mx-auto" src={item.icon} alt="" />
                    <h3 className="text-2xl font-bold text-center">
                      {item.title}
                    </h3>
                    <p className=" lg:text-sm text-base font-medium text-[#4B4B4B] text-center">
                      {item.info}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="h-12 md:h-24"></div>
        {/* End Why Choose Keyla Tech */}
        {/* Additional Info */}
        <div className="px-6 xl:px-32 w-full flex flex-col-reverse md:flex-row items-center gap-7 md:gap-0">
          <div className="w-full md:w-6/12 space-y-6">
            <h3 className="font-bold text-2xl md:text-3xl lg:text-4xl">
              Why Choose Keyla Tech- <br /> Accelerator?
            </h3>
            <ul className="list-disc text-primary space-y-3">
              <li className="flex items-center gap-x-3">
                <span>
                  <img src={Trophy} alt="" />
                </span>
                <span>
                  <h4 className="font-bold text-lg ">Proven Success</h4>
                  <p className="md:w-4/6 text-[#4B4B4B] font-normal text-sm md:text-base tracking-wider">
                    Keyla Technologies has a track record of delivering quality
                    services and fostering innovation.
                  </p>
                </span>
              </li>
              <li className="flex items-center gap-x-3">
                <span>
                  <img src={Support} alt="" />
                </span>
                <span>
                  <h4 className="font-bold text-lg">Holistic Support</h4>
                  <p className="md:w-4/6 text-[#4B4B4B] font-normal text-sm md:text-base tracking-wider">
                    We go beyond funding, providing comprehensive support to
                    ensure your success.
                  </p>
                </span>
              </li>
              <li className="flex items-center gap-x-3">
                <span>
                  <img src={Hub} alt="" />
                </span>
                <span>
                  <h4 className="font-bold text-lg">Innovation Hub</h4>
                  <p className="md:w-4/6 text-[#4B4B4B] font-normal text-sm md:text-base tracking-wider">
                    Join a vibrant community of tech enthusiasts and
                    entrepreneurs, where collaboration and innovation thrive.
                  </p>
                </span>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-6/12 flex items-center justify-center">
            <img className="w-full lg:w-[75%] mx-auto" src={Image4} alt="" />
          </div>
        </div>

        {/* Application Criteria */}
        <div className="h-12 md:h-24"></div>
        <div className="px-6 xl:px-32 w-full flex flex-col md:flex-row items-center bg-black py-6 md:py-24 md:gap-10">
          <div className="w-full md:w-6/12 mb-6 md:mb-0">
            <img className="w-full lg:w-[80%] mx-auto" src={Image5} alt="" />
          </div>

          <div className="w-full md:w-6/12 space-y-5">
            <h3 className="text-white text-2xl md:text-3xl">
              Application Criteria
            </h3>
            <ul className="list-disc text-primary ml-4">
              <li>
                <span className="text-white font-normal text-sm md:text-base tracking-wider">
                  Open to individuals and startups
                </span>
              </li>
              <li>
                <span className="text-white font-normal text-sm md:text-base tracking-wider">
                  Passion for innovation and a drive for success
                </span>
              </li>
              <li>
                <span className="text-white font-normal text-sm md:text-base tracking-wider">
                  Tech product ideas in any field
                </span>
              </li>
            </ul>
            <button
              className="bg-primary px-4 py-2 rounded-full text-sm md:text-base text-white"
              onClick={toggleModal}
            >
              Apply Now
            </button>
          </div>
        </div>
        <Modal isVisible={showModal} onClose={toggleModal} />
      </Layout>
    </>
  )
}

export default AcceleratorProgram
